<!-- 不符原因弹窗 -->
<template>
  <el-dialog
    width="50%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'不符原因':'不符原因'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="120px">

      <el-row :gutter="15">
        <el-col :span="12">
          <el-form-item label="订单号：">
            <span>{{data.order_id}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报案号：">
            <span>{{data.case_number}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :span="12">
          <el-form-item label="故障客户：">
            <span>{{data.owners}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="故障车牌：">
            <span>{{data.license_plate}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :span="12">
          <el-form-item label="救援城市：">
            <span>{{data.rescue_area}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同价：">
            <span>{{data.contract_price}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :span="24">
          <el-form-item label="不符原因：" prop="reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入内容"
              v-model="form.reason">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import {capacity_reconciliationreject} from "@/api/finance";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.form.reason);
          if(this.form.reason == '' || this.form.reason == undefined){
            this.$message.warning('请输入原因')
          }else {
            let data = {
              reason: this.form.reason
            }
            capacity_reconciliationreject(this.data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
